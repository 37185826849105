.loader-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > .loader {
        display: flex;
        align-items: center;

        & > .loader-img {
            width: 50px;
            height: 50px;
            padding: 10px;

            & > svg {
                width: 100%;
                height: 100%;
            }
        }

        & > .loader-texts {
            & > .primary-text {
                font-size: 16px;
            }

            & > .secondary-text {
                font-size: 12px;
            }
        }
    }
}
