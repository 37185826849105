@import 'variables.scss';

body {
    .swal2-styled {
        &.swal2-confirm, &.swal2-cancel {
            border-radius: 38px;
        }

        &.swal2-confirm {
            background-color: $color_primary;
        }
    }
}

body.skoda {
    .swal2-styled.swal2-confirm {
        background-color: $color_primary_skoda;
    }
}
