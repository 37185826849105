@import '../../style/variables.scss';

.section-container {
    & > .btn-part {
        display: flex;
        justify-content: center;
        padding: 10px;

        & > .damage-btn {
            border: none;
            background-color: transparent;
            color: $color_primary;
            font-weight: 500;

            &:hover {
                color: $color_secondary;
            }

            &:disabled {
                color: gray;
            }
        }
    }
}

body.skoda {
    .section-container > .btn-part > .damage-btn {
        color: $color_primary_skoda;
    }
}