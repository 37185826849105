.sig-panel {
    flex: 0 1 300px;
    display: flex;
    justify-content: center;
    border: solid 1px gray;
    height: 100px;
    cursor: pointer;
    // padding: 5px;

    .sig-img {
        max-height: 100%;
    }
}

.sig-canvas-container {
    display: flex;
    justify-content: center;

    .sig-canvas {
        border: solid 1px black;
        width: 100%;
        // height: 100%;
    }
}

.sig-modal {
    max-width: 640px;
}