.labval-item {
    flex: 1 1 50%;
    padding: 10px;

    label {
        font-size: 12px;
        font-weight: 500;

        & > .after-label-ico {
            margin-left: 10px;
        }
    }

    @media (max-width: 575px) {
        flex: 1 1 100%;
    }
}
