@import '../style/variables.scss';

.form-section-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;

    &.with-delimiter {
        position: relative;

        &:after {
            content: " ";
            position: absolute;
            height: 1px;
            right: 24px;
            left: 24px;
            bottom: 0;
            background-color: #c3c3c3;
        }
    }

    & > .title {
        margin-bottom: 10px;
        font-weight: 500;
        display: flex;

        & > .title-text {
            flex: 1 1 auto;
        }

        & > .title-logo {
            max-width: 200px;
            max-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            & > img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        & > .after-title-ico {
            margin-left: 10px;
        }
    }

    & > .prop-list {
        display: flex;
        flex-flow: wrap;
    }

    & > .btn-part {
        display: flex;
        justify-content: center;
        padding: 10px;

        & > .section-btn {
            border: none;
            background-color: transparent;
            color: $color_primary;
            font-weight: 500;

            &:hover {
                color: $color_secondary;
            }

            &:disabled {
                color: gray;
            }
        }
    }
}
