.login-modal.modal-dialog-centered {
    align-items: center;

    &.hide-close {
        .modal-header .close {
            display: none;
        }
    }

    .login-buttons-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: fit-content;
        margin: 0 auto;

        & > button {
            flex: 1 0 auto;
            margin: 10px;
            text-align: center;

            & > img, svg {
                height: 16px;
                margin-right: 10px;
            }
        }
    }

    p {
        font-size: 12px;
        margin: 15px 0 0 0;
        text-align: center;
    }
}
