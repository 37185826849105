.error-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > .error {
        display: flex;
        align-items: center;

        & > .error-img {
            width: 50px;
            height: 50px;
            padding: 10px;
            color: red;

            & > svg {
                width: 100%;
                height: 100%;
            }
        }

        & > .error-text {
            font-size: 16px;
        }
    }
}
