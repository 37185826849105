﻿$color_primary: #2d67a7;
$color_secondary: #39c;
$color_black_0: #000;
$color_black_1: #272727;
$color_black_2: #444;
$color_back_1: #f6f7f8;
$color_white_0: #fff;

$color_primary_skoda: #4ba82e;
$color_secondary_skoda: #4a4a4a;
$color_black_0_skoda: #4a4a4a;
$color_black_1_skoda: #4a4a4a;
$color_black_2_skoda: #4a4a4a;
$color_back_1_skoda: #f6f7f8;
