@import '../../style/variables.scss';
@import '../../components/PageForm.js.scss';

.page-from-container {
    .page-form {
        .section-container {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            padding: 20px;

            .title {
                margin-bottom: 10px;
                font-weight: 500;
                display: flex;

                .title-text {
                    flex: 1 1 auto;
                }

                .title-logo {
                    max-width: 200px;
                    max-height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    & > img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                & > .after-title-ico {
                    margin-left: 10px;
                }
            }

            .prop-list {
                display: flex;
                flex-flow: wrap;

                .labval-item {
                    flex: 1 1 50%;
                    padding: 10px;

                    label {
                        font-size: 12px;
                        font-weight: 500;

                        & > .after-label-ico {
                            margin-left: 10px;
                        }
                    }

                    @media (max-width: 575px) {
                        flex: 1 1 100%;
                    }
                }

                .photo-item {
                    flex: 0 1 50%;
                    padding: 10px;
                    display: flex;
                    cursor: pointer;
                    border: solid 1px transparent;
                    position: relative;

                    .photo-item-img-container {
                        flex: 0 0 auto;
                        width: 50px;
                        height: 50px;
                        padding: 5px;

                        & > img {
                            max-width: 100%;
                            max-height: 100%;
                            border-radius: 2px;
                        }

                        .no-img {
                            width: 100%;
                            height: 100%;
                            color: lightgray;
                        }
                    }

                    .info-container {
                        flex: 1 1 auto;
                        display: flex;
                        flex-direction: column;
                        padding: 0 5px;

                        .info-title {
                            font-weight: bold;
                        }

                        .info-description {
                            font-size: 12px;
                        }
                    }

                    .plus-container {
                        flex: 0 0 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $color_primary;
                    }

                    & > input {
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        width: 1px;
                        height: 1px;
                    }

                    &:hover {
                        border: solid 1px lightgray;
                    }

                    @media (max-width: 575px) {
                        flex: 0 1 100%;
                    }
                }
            }

            &.with-delimiter {
                position: relative;

                &:after {
                    content: " ";
                    position: absolute;
                    height: 1px;
                    right: 24px;
                    left: 24px;
                    bottom: 0;
                    background-color: #c3c3c3;
                }
            }

            .sig-item {
                display: flex;
                flex-flow: wrap;

                label {
                    flex: 1 1 auto;
                }
            }

            .buttons {
                display: flex;
                justify-content: flex-end;
                flex-flow: wrap;

                & > * {
                    margin: 0 10px;
                }
            }

            .info-msg {
                font-size: 14px;
                text-align: center;
            }
        }
    }
}

body.skoda {
    .page-from-container heck-vehicle-container .section-container .prop-list .photo-item .plus-container {
        color: $color_primary_skoda;
    }
}
