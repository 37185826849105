.error-page-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > .error-page-content {
        display: flex;
        //align-items: center;

        & > .icon-part {
            margin: 5px;
        }

        & > .text-part {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            max-width: 400px;

            & > .title {
                font-size: 18px;
                font-weight: 500;
                margin: 5px 10px;
            }

            & > .message {
                font-size: 14px;
                margin: 5px 10px;
            }

            & > .link {
                text-align: right;
                margin: 5px 10px;
                font-size: 14px;
                color: blue;
                cursor: pointer;
            }
        }
    }
}
