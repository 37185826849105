.reservation-modal {
    .consent-container {
        font-size: 12px;
        padding: 0 10px;
        margin: 0;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .price-tooltip {
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        width: auto;
        opacity: 1;

        .info-text {
            font-weight: 500;
        }
    }
}
