﻿@import '../style/variables.scss';

.page-from-container {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;

    @media (min-width: 576px) {
        min-height: initial;
        padding: 10px 10px 30px 10px;
    }
}

.page-form {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: $color_white_0;
    box-shadow: 0px 0px 5px gray;
    max-width: 800px;
    width: 100%;

    @media (min-width: 576px) {
        flex: 0 1 auto;
        border-radius: 5px;
    }
}
