.consent-list {
    & > .consent-item {
        padding: 5px;

        & > .form-check {

            & > label {
                cursor: pointer;
                font-size: 13px;

                & > input {
                    cursor: pointer;
                }
            }

            &:hover > label {
                //font-weight: 600;
            }
        }
    }
}
