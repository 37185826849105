.datetime-input-container {
    display: flex;
    align-items: center;

    .form-control {
        padding: 4px 8px;
        text-align: right;
        height: 32px;

        &.error {
            color: red;
        }
    }

    .date-input-container {
        width: 104px;
    }

    .time-input-container {
        width: 68px;
    }

    .form-control.empty {
        opacity: 0.3;
    }

    .picker-btn {
        padding: 5px 5px 10px 5px;
        margin-left: -5px;
        margin-right: 5px;
        color: #666;
        font-size: 16px;
    }

    &:not(.readonly) .picker-btn {
        cursor: pointer;

        &:hover {
            color: #333;
        }
    }

    .react-datepicker-wrapper {
        width: auto;

        .react-datepicker__input-container {
            display: flex;
            color: #666;

            &:hover {
                color: #333;
            }
        }
    }

    .time-picker-dialog {
        z-index: 1;

        &.hidden {
            visibility: hidden;
            pointer-events: none;
        }
    }
}
