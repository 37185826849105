.photos-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .btn-container {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;

        .add-photo {
            width: 30px;
            height: 30px;
            padding: 0;
            min-width: initial;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            background-color: transparent;
            border: solid 1px lightgray;
            border-radius: 5px;
            color: gray;

            &:hover {
                border-color: gray;
                color: black;
            }

            & > input {
                position: absolute;
                top: 0;
                // right: 0;
                // bottom: 0;
                left: 0;
                opacity: 0;
                width: 1px;
                height: 1px;
            }
        }

        &:not(:first-child) {
            // margin-top: 10px;
            // margin-left: 10px;
        }
    }

    .img-container {
        width: 50px;
        height: 50px;
        padding: 5px;

        img {
            max-width: 40px;
            max-height: 40px;
            cursor: pointer;
        }
    }
}
