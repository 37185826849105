.damage-item {
    flex: 0 1 50%;
    padding: 10px;
    display: flex;
    border: solid 1px transparent;

    &.has-photo {
        cursor: pointer;
    }

    .damage-item-img-container {
        flex: 0 0 auto;
        width: 50px;
        height: 50px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 2px;
        }

        .no-img {
            width: 100%;
            height: 100%;
            color: lightgray;
        }
    }

    .info-container {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        padding: 0 5px;

        .info-title {
            font-weight: bold;
        }

        .info-description {
            font-size: 12px;
        }
    }

    &:hover {
        border: solid 1px lightgray;
    }

    @media (max-width: 575px) {
        flex: 0 1 100%;
    }
}
