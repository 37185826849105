html {
    font-size: 14px;

    .login-ico {
        font-size: 16px;
    }
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar {
    padding: 0;
    flex-wrap: nowrap;

    .app-logo, .navbar-title {
        padding: 10px;
    }

    .user-menu {
        padding: 5px;

        & > button {
            padding: 5px 10px;

            & > *:not(:first-child) {
                margin-left: 5px;
            }
        }
    }

    .cs-login-link {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 10px 15px;

        & > *:not(:first-child) {
            margin-left: 5px;
        }
    }

    a.navbar-brand {
        white-space: normal;
        word-break: break-all;
    }

    .brand-img {
        height: 34px;
    }

    .brand-img-narrow {
        max-height: 34px;
        max-width: 100px;
    }

    & > * {
        &.navbar-toggler {
            flex: 0 0 auto;
        }
    }

    .navbar-title {
        text-align: center;
        font-weight: bold;
        flex: 1 1 50%;
    }

    .nav-link, .user-nav {
        & > i {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    .user-nav {
        text-decoration: none;

        &:focus {
            box-shadow: none;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .nav-contact-btn {
        flex: 0 0 auto;
        padding: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666;

        & > svg {
            height: 18px;
        }
    }
}

@media (max-width: 575px) {
    .navbar {
        .brand-img {
            display: none;
        }
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm .navbar-collapse {
        flex-basis: 10px;
    }

    .navbar {
        .brand-img-narrow {
            display: none;
        }
    }
}
