@import 'variables.scss';

.form-label {
    font-size: 12px;
    font-weight: 500;
}

.form-switch {
    padding: 0 2px;
    margin-bottom: 1rem;
    display: flex;
    align-content: center;

    & > .form-check-label {
        flex: 1 1 auto;
        font-size: 12px;
        margin: 2px 8px;
    }

    & > .form-check-input {
        appearance: none;
        font-size: 18px;
        margin: 3px 8px;

        &:checked {
            background-color: $color_primary;
            border-color: $color_primary;
        }
    }
}

body.skoda {
    .form-switch {
        & > .form-check-input {
            &:checked {
                background-color: $color_primary_skoda;
                border-color: $color_primary_skoda;
            }
        }
    }
}