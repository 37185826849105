@import '../../style/variables.scss';

.ftp-modal {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    .ftp-values {
        padding: 5px;

        .ftp-item-container {
            position: relative;
            cursor: pointer;
        }

        .ftp-item {
            padding: 2px 5px;
            text-align: center;

            &:hover {
                background-color: #eceff1;
            }

            &.chosen {
                color: white;
                background-color: $color_primary;
                font-weight: bold;
            }
        }
    }
}

body.skoda {
    .ftp-modal {
        .ftp-values {
            .ftp-item {
                &.chosen {
                    background-color: $color_primary_skoda;
                }
            }
        }
    }
}