.dropdown-input {
    & > .dropdown-menu {
        padding: 0;
        max-height: 130px;
        overflow: auto;
        width: 100%;

        & > .selected {
            background-color: lightgray;
        }
    }
}