.phone-input-container {
    display: flex;

    & > .cs-form-select {
        min-width: 90px;
    }

    & > .phone-value-container {
        flex: 1 1 auto;
        display: flex;

        & > .link-btn {
            font-size: 14px;
            padding: 0.375rem 0.75rem;
            white-space: nowrap;
        }
    }
}
