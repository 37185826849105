.readonly-input-container {
    display: flex;

    & > .readonly-div-control {
        flex: 1 1 auto;
    }

    & > .link-btn {
        font-size: 14px;
        padding: 0.375rem 0.75rem;
    }
}
