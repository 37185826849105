.parking-marker-label-container {
    position: relative;

    .parking-marker-label {
        font-weight: 400;
        overflow: hidden;
    }

    .click-overlay {
        position: absolute;
        top: -10px;
        right: -10px;
        bottom: -10px;
        left: -10px;
        cursor: pointer;
        //background-color: aqua;
    }
}

.gm-style-iw-d:has(.parking-marker-label) {
    overflow: visible !important;
    padding-right: 8px;
    padding-bottom: 8px;

    & + button {
        display: none !important;
        opacity: 0;
    }
}

.gm-style-iw-c:has(.parking-marker-label) {
    top: 10px;
    padding-left: 8px;
    padding-top: 8px;

    & > .gm-style-iw-chr {

        & > .gm-style-iw-ch {
            padding-top: 10px;
        }

        & > button {
            display: none !important;
        }
    }

    & + .gm-style-iw-tc {
        display: none;
    }
}

.gm-style iframe + div {
    border: none !important;
}
