@import '../style/variables.scss';

.contacts-modal {
    a {
        color: $color_primary;

        & > svg {
            margin-right: 10px;
        }

        & > .prefix-ico {
            width: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 2px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    & > .modal-content {
        & > .modal-body {
            & > .label {
                font-weight: 600;
                margin-bottom: 5px;

                &.break {
                    margin-top: 20px;
                }
            }

            & > .file {
                margin-top: 5px;
            }

            & > .tel {
                display: flex;
                align-items: center;
                margin-top: 2px;

                & > *:not(:first-child) {
                    margin-left: 5px;
                }
            }

            & > .telmail {
                display: flex;
                align-items: center;
                flex-flow: row;
                flex-wrap: wrap;
                margin-top: 2px;

                & > * {
                    white-space: nowrap;
                    margin-right: 10px;
                }

                & > span {
                    margin-left: 10px;
                }
            }
        }
    }
}
