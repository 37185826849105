.find-close-container {
    position: absolute;
    top: 10px;
    right: 50%;
    left: 50%;
    width: fit-content;
    transform: translateX(-50%);

    .find-close-btn {
        background-color: #fff;
        border-radius: 22px;
        padding: 10px 15px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
        font-weight: 500;
        cursor: pointer;
        white-space: nowrap;
    }

    @media (min-width: 576px) {
        display: none;
    }
}
