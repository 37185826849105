@import '../../style/variables.scss';

$color_border: #777;

.contextmenu {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: fixed;
	z-index: 100;
	display: none;

	&.show {
		display: block;
	}
}
.contextmenu-box {
	background-color: $color_white_0;
	border: 1px solid $color_border;
	color: $color_black_0;
	position: fixed;

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		user-select: none;

		hr {
			margin: 0;
			padding: 0;
			color: $color_border;
		}

		li {
			margin: 0;
			padding: 0;
			line-height: 1.5;
			cursor: pointer;
			white-space: nowrap;

			a,
			.context-link {
				text-decoration: none;
				color: $color_black_0;
				display: block;
				padding: 3px 6px;
			}

			&:hover {
				background-color: #f2f2f2;
			}

			&.disabled {
				cursor: not-allowed;
				color: $color_border;

				&:hover {
					background-color: $color_white_0;
				}
			}
		}
	}
}
