.side-panel-selector {
    display: flex;

    .side-panel-selector-item {
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        color: gray;
        cursor: pointer;
        border-radius: 5px;

        &.selected {
            color: black;
        }

        &:hover {
            background-color: #eee;
        }

        & > .ico {
            flex: 0 0 auto;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
