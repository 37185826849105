@import "toastr";
@import 'variables.scss';


html,
body,
#root,
.carshare-app-container {
    height: 100%;
}

body {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    --bs-body-line-height: 28px;

    .carshare-app-container {
        display: flex;
        flex-direction: column;

        &.form > header {
            background-color: $color_back_1;
        }

        & > .app-container {
            background-color: $color_back_1;
        }

        & > header {
            flex: 0 0 auto;
        }

        & > .app-container {
            flex: 1 1 auto;
            overflow: auto;
            height: 100%;
        }
    }

    .carshare-content-container {
        height: 100%;
        display: flex;

        .carshare-map-container {
            position: relative;
            flex: 1 1 auto;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: stretch;

            .csmap {
                flex: 1 1 auto;

                *:focus-visible {
                    outline: none;
                }
            }
        }

        .desktop-filter {
            flex: 0 0 auto;
            min-width: 200px;
            flex-direction: column;
        }

        .panel-buttons-overlay-container {
            position: absolute;
            top: 0px;
            right: 0px;
            display: flex;
            flex-direction: column;
        }

        .map-panel-container {
            position: absolute;
            top: 0px;
            right: 38px;
            padding: 10px;

            &.touch {
                right: 50px;
            }

            .map-panel {
                background-color: $color_white_0;
                border-radius: 8px;
                padding: 4px;
                box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
            }
        }

        .mobile-reservations {
            padding-bottom: 24px !important;
            max-height: 100%;
            overflow: auto;
            display: flex;
            flex-direction: column;

            & > .mobile-panel-container {
                min-height: 250px;
                min-width: 200px;
                max-height: 100%;
                overflow: auto;
                position: relative;
                display: flex;
                flex-direction: column;
            }
        }

        .mobile-filter {
            padding-bottom: 24px !important;
            max-height: 100%;
            overflow: auto;
            display: flex;
            flex-direction: column;
        }

        .car-filter {
            min-width: 50px;
            background-color: $color_white_0;
            max-height: 100%;
            overflow: auto;
            display: flex;
            flex-direction: column;

            .car-filter-header {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #dee2e6;

                .car-filter-title {
                    flex: 1 1 auto;
                    padding: 10px;
                    font-weight: 500;
                }

                .check-all {
                    display: flex;
                    align-items: center;
                    padding-right: 10px;
                    cursor: pointer;

                    .all-text {
                        padding: 5px;
                    }
                }
            }

            .car-filter-list {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                align-items: stretch;
                max-height: 100%;
                overflow: auto;

                .car-filter-item {
                    display: flex;
                    cursor: pointer;

                    .img-container {
                        flex: 1 1 auto;
                        padding: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        img {
                            max-width: 80px;
                            max-height: 60px;
                            border-radius: 2px;

                            @media (min-width: 576px) {
                                max-width: 200px;
                                max-height: 100px;
                            }
                        }
                    }

                    .title {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        font-weight: 500;
                    }

                    .check-container {
                        flex: 0 0 auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 10px 10px 10px 0;
                    }
                }
            }
        }
    }

    .custom-select-item {
        img {
            max-height: 30px;
            max-width: 100px;
            border-radius: 2px;
        }

        & > *:not(:first-child) {
            margin-left: 10px;
        }
    }

    a {
        color: #0366d6;
        text-decoration: none;
    }

    code {
        color: #e01a76;
    }

    .btn-primary {
        color: #fff;
        background-color: #1b6ec2;
        border-color: #1861ac;
    }

    .form-group-row {
        display: flex;
        flex-wrap: wrap;
        margin: 0;

        & > * {
            flex: 1 0 auto;
        }
    }

    .custom-control-container {
        position: relative;
        min-height: 38px;

        &:before {
            content: " ";
            position: absolute;
            height: 1px;
            right: 4px;
            left: 4px;
            bottom: 0;
            background-color: #c3c3c3;
        }

        &:after {
            content: " ";
            position: absolute;
            height: 2px;
            width: 0;
            left: 4px;
            bottom: 0;
            background-color: #c3c3c3;
            transition: all 0.25s ease-out;
        }

        &:not(.readonly):hover {
            &:after {
                width: calc(100% - 8px);
            }
        }

        &:not(.readonly):focus-within {
            &:after {
                width: calc(100% - 8px);
            }
        }

        .form-control:disabled,
        .form-control[readonly] {
            background-color: #fff;
            opacity: 1;
        }
    }

    .btn {
        border-radius: 38px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .btn-carshare {
        background-color: $color_primary;
        color: #fff;

        &:hover {
            background-color: $color_primary;
            color: #fff;
        }

        &:focus {
            box-shadow: none;
        }

        &.disabled {
            color: #fff;
            background-color: #c3c3c3;
            border-color: #c3c3c3;
        }
    }

    .form-control {
        border: 0;

        &:focus {
            box-shadow: none;
        }
    }

    .form-control,
    .cs-form-select {
        font-size: 14px;
    }

    .note-text {
        font-size: 14px;
    }

    .text-red {
        color: red;
    }

    .readonly-div-control {
        font-size: 14px;
        padding: 0.375rem 0.75rem;
        color: #495057;
        cursor: text;
    }

    .xshare-note {
        text-align: center;
        font-weight: bold;
    }

    .horiz-delimiter {
        position: relative;

        &:before {
            position: absolute;
            height: 2px;
            left: 10px;
            right: 10px;
            background-color: black;
            content: "";
        }
    }

    button,
    a,
    .btn,
    .nav-link {
        display: flex;
        align-items: center;

        & > svg {
            margin: 3px 5px;
        }
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }

    .hide-on-mobile {
        display: none;
    }

    .no-padding {
        padding: 0;
    }

    .gmap-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666;
        background-color: #fff;
        border: none;
        outline: none;
        width: 28px;
        height: 28px;
        border-radius: 8px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
        margin: 10px;
        padding: 0;
        font-size: 16px;

        &.touch {
            width: 40px;
            height: 40px;
        }

        &.reserv-map-btn {
            margin-top: 0;
        }

        &.no-shadow {
            box-shadow: none;
        }
    }

    .custom-zoom-control {
        display: flex;
        flex-direction: column;
        color: #666;
        background-color: #fff;
        border: none;
        outline: none;
        border-radius: 8px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        margin: 0 10px;
        padding: 0;
        font-size: 16px;

        & > .custom-zoom-control-delimiter {
            background-color: #e6e6e6;
            height: 1px;
            padding: 0;
            margin: 0 5px;
        }

        & > .gmap-btn {
            box-shadow: none;
            margin: 0;
        }
    }

    .custom-activity-control.active {
        & > .gmap-btn {
            color: $color_primary;
        }
    }

    .link-btn {
        display: inline;
        border: none;
        background-color: transparent;
        color: $color_primary;
        padding: 0;
        margin: 0;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        &:disabled {
            color: gray;
        }
    }

    .label-info-ico {
        color: #666;
        margin-left: 5px;
    }

    @media (min-width: 576px) {
        .modal-sm {
            max-width: 350px;
        }

        .hide-on-mobile {
            display: block;

            &.flex {
                display: flex;
            }
        }

        .hide-on-desktop {
            display: none !important;
        }
    }
}

.clicable {
    cursor: pointer;
}

.dropdown-item {
    padding: 8px 24px;
}

body.skoda {
    .form-control,
    .cs-form-select {
        font-size: 16px;
    }

    .btn-carshare {
        background-color: $color_primary_skoda;
    }

    .custom-activity-control.active {
        & > .gmap-btn {
            color: $color_primary_skoda;
        }
    }

    .carshare-content-container .car-filter {
        background-color: $color_back_1_skoda;
    }

    .carshare-app-container {
        &.form > header {
            background-color: $color_back_1_skoda;
        }

        & > .app-container {
            background-color: $color_back_1_skoda;
        }
    }
}
