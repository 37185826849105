.takeover-info-modal {
    p {
        text-align: center;
    }

    .app-link-qr > img {
        height: 60px;
    }

    .app-link-btn {
        display: flex;
        align-items: center;

        .app-btn {
            cursor: pointer;
        }

        img {
            height: 36px;
        }
    }

    .app-link-qr-1 {
        grid-area: qr1;
    }

    .app-link-btn-1 {
        grid-area: btn1;
    }

    .app-link-qr-2 {
        grid-area: qr2;
    }

    .app-link-btn-2 {
        grid-area: btn2;
    }

    .app-link-btns {
        text-align: center;
        display: grid;
        grid-template-areas: 'qr1 btn1' 'qr2 btn2';
        grid-column-gap: 10px;
        grid-row-gap: 5px;
    }

    .flex-col-center-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        & > *:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}
