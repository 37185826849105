.modal-dialog.damage-photo-modal {
    display: flex;
    justify-content: center;

    & > .modal-content {
        width: auto;
        max-width: 1024px;

        .modal-body {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            & > .description {
                margin-bottom: 10px;
            }

            & > .photo-part {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                overflow: hidden;

                & > .img-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    overflow: hidden;
                    padding-left: 50px;
                    padding-right: 50px;
                    height: 100%;

                    & > img {
                        flex: 0 1 auto;
                        max-width: 100%;
                        max-height: 100%;
                        object-fit: contain;
                        border-radius: 4px;
                    }
                }

                & > .prev {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    & > svg {
                        width: 50px;
                        height: 50px;
                        opacity: 0;
                    }

                    &.active {
                        & > svg {
                            opacity: 0.5;
                        }

                        &:hover {
                            & > svg {
                                opacity: 1;
                            }
                        }
                    }
                }

                & > .next {
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    & > svg {
                        width: 50px;
                        height: 50px;
                        opacity: 0;
                    }

                    &.active {
                        & > svg {
                            opacity: 0.5;
                        }

                        &:hover {
                            & > svg {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 576px) {
    .modal-dialog.damage-photo-modal {
        padding: 5px;
        margin: 0;
        max-width: 100%;
        max-height: 100%;
    }
}
