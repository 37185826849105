body {
    .modal-content {
        border-radius: 8px;

        .modal-footer {
            border-top: none;
            justify-content: center;

            &.right-align {
                justify-content: flex-end;
            }
        }

        .modal-header {
            border-bottom: none;
            padding-bottom: 0.75rem;

            .modal-title {
                font-size: 22px;
            }

            &.title-btn-modal-header {
                .modal-title {
                    flex: 1 1 auto;
                    display: flex;
                    align-items: center;

                    .title-text {
                        flex: 1 1 auto;
                    }

                    .modal-header-contact-btn {
                        padding: 8px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #666;

                        & > svg {
                            height: 18px;
                        }
                    }
                }

                .close {
                    line-height: 1.2;
                }
            }

            .close {
                color: #999;
                opacity: 1;

                &:not(:disabled):not(.disabled):hover {
                    color: #666;
                    opacity: 1;
                }
            }
        }

        .modal-body {
            padding-top: 0.75rem;
        }
    }

    .modal-backdrop.show {
        opacity: 0.3;
    }
}
