.check-input {
    position: relative;
    cursor: pointer;
    min-width: 20px;
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .circle {
        width: 12px;
        height: 12px;
        border: solid 1.5px grey;
        border-radius: 12px;
    }

    .check {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 18px;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        .circle {
            border-width: 2px;
        }
    }

    &.checked {
        .check {
            display: flex;
        }
    }
}
