.reserv-list-container {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .reserv-list-header {
        display: flex;
        align-items: center;

        .title {
            flex: 1 1 auto;
            font-weight: 500;
            padding: 5px 10px;
        }

        .check-all {
            display: flex;
            align-items: center;
            padding-right: 10px;
            cursor: pointer;

            .all-text {
                padding: 5px;
            }
        }
    }

    .delimiter {
        position: relative;
        padding: 2px 0;

        &:before {
            content: '';
            position: absolute;
            left: 10px;
            right: 10px;
            height: 1px;
            background-color: #dee2e6;
        }
    }

    .reserv-list-list {
        height: 100%;
        overflow: auto;

        .reserv-list-item {
            padding-top: 10px;

            .name {
                padding: 0 10px;
                font-weight: 500;

                &.active {
                    cursor: pointer;
                }

                & > *:not(:first-child) {
                    margin-left: 5px;
                }
            }

            .description {
                display: flex;
                align-items: center;
                padding-left: 10px;

                .img-container {
                    img {
                        border-radius: 2px;
                        max-height: 24px;
                        max-width: 50px;
                    }
                }

                .text {
                    font-size: 14px;
                    padding: 5px 10px;
                }
            }

            .time-container {
                display: flex;
                align-items: flex-start;

                .ico-container {
                    color: #888;
                    padding: 5px 10px;
                }

                .table-container {
                    padding: 4px 2px;

                    table {
                        td {
                            padding: 1px 3px;
                        }
                    }
                }
            }

            .state-container {
                display: flex;
                align-items: center;

                .state-ico {
                    padding: 5px 5px 5px 12px;
                    font-size: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .state-text {
                    flex: 1 0 auto;
                    padding: 5px 10px;
                }

                &.good {
                    color: green;
                }

                &.ready {
                    color: sandybrown;
                }
            }

            .buttons {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 5px;

                button {
                    flex: 0 0 auto;
                    min-width: 150px;
                    margin: 5px;
                    justify-content: center;
                }
            }
        }

        &.some-info {
            display: flex;
            flex-direction: column;
            align-items: center;

            .text-container {
                width: 200px;
                text-align: center;
                padding: 50px 10px;
            }

            button {
                margin-bottom: 10px;
            }
        }
    }
}
